<template>
  <div class="channelOne">
    <div class="back" @click="goBack">
      <i class="el-icon-arrow-left"></i> 返回
    </div>
    <div class="topContent">
      <div class="left">
        <img
          src="https://yanjingyongyangyoueryuan.oss-cn-beijing.aliyuncs.com/logo.png"
          alt=""
        />
      </div>
      <div class="right">
        <div class="rightTop">北京朝阳燕京雍阳幼儿园</div>
        <div class="rightBtm">
          Beijing Chaoyang Yanjing Yongyang Kindergarten
        </div>
      </div>
    </div>

    <div class="addKehu" @click="toAdd">+新建客户</div>
    <div class="khDetail" @click="toDetail">+客户详情</div>
  </div>
</template>

<script>
export default {
  name: "channelTwo",
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    toAdd() {
      this.$router.push({ path: "/channelThree" });
    },
    toDetail() {
      this.$router.push({ path: "/channelFour" });
    },
  },
};
</script>
<style scoped>
.channelOne {
  width: 100vw;
  height: 100vh;
  background-image: url("https://yanjingyongyangyoueryuan.oss-cn-beijing.aliyuncs.com/bg.png");
  background-size: 100% 100%;
}
.back {
  width: 80px;
  height: 60px;
  font-size: 15px;
  color: white;
  padding-top: 20px;
  padding-left: 20px;
}
.topContent {
  width: 335px;
  margin: 0 auto;
  padding-top: 60px;
  display: flex;
  justify-content: space-around;
}
.left {
  width: 40px;
  height: 40px;
}
.left img {
  width: 100%;
  height: 100%;
}
.right {
  width: 330px;
  margin-left: 10px;
}
.rightTop {
  font-size: 16px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 16px;
}
.rightBtm {
  font-size: 12px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  color: #ffffff;
}

.addKehu {
  width: 343px;
  height: 60px;
  background: #07c1ab;
  border-radius: 100px 100px 100px 100px;
  opacity: 1;
  font-size: 16px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  line-height: 60px;
  margin: 0 auto;
  margin-top: 200px;
}
.khDetail {
  width: 343px;
  height: 60px;
  background: #ed9bd3;
  border-radius: 100px 100px 100px 100px;
  opacity: 1;
  font-size: 16px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  line-height: 60px;
  margin: 0 auto;
  margin-top: 40px;
}
</style>
